.btn-delete {
    background-color: #ff0000;
    color: #ffffff;
    cursor: 'pointer';
}

a.link-nav {
    color: #000000;
    text-decoration: unset;
}

a.link-nav {
    color: #000000;
    text-decoration: none;
}

.MuiFormControl-root {
    min-width: 200px;
}

.font-10 {
    font-size: 10px;
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-26 {
    font-size: 26px;
}

.font-bold {
    font-weight: 500;
}

.font-extra-bold {
  font-weight: 800;
}