@font-face {
  font-family: "Prompt";
  src: local("Prompt"), url(./assets/fonts/Prompt-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Prompt";
  src: local("Prompt"),
    url(./assets/fonts/Prompt-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Prompt";
  src: local("Prompt"), url(./assets/fonts/Prompt-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Prompt";
  src: local("Prompt"),
    url(./assets/fonts/Prompt-SemiBold.ttf) format("truetype");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: "Prompt", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Prompt", sans-serif !important;
  letter-spacing: 0.5px;
}

code {
  font-family: "Prompt", monospace;
}

.swal2-container {
  z-index: 20000 !important;
}

.print-pdf-sarabun p {
  font-family: "Sarabun" !important;
  letter-spacing: 0.5px;
}

/* *[class*="jss"],
*[class*="jss"]:hover {
  color: unset !important;
  background-color: unset !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* Firefox */
input[type="number"] {
  appearance: textfield; /* property มาตรฐาน */
  -moz-appearance: textfield; /* สำหรับ Firefox */
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
