*[class$="jss"],
*[class$="jss"]:hover {
  color: unset;
  padding: unset;
  margin: unset;
  background-color: unset;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-by {
  min-width: 120px !important;
  font-size: 14px !important;
  text-align: center !important;
}

.update-by span {
  font-size: 12px !important;
}

.status {
  width: 160px;
}

/* .Toastify__toast-container{ */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
  border-left: 5px solid var(--toastify-color-success) !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  border-left: 10px solid var(--toastify-color-error) !important;
}

.container-card .card {
  border: 1px solid #e5e5e5 !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  border: 1px solid #e5e5e5 !important;
}

/* Utility */
h5.required::after,
label.required::after {
  content: "*";
  color: red;
}

label.Mui-required .MuiInputLabel-asterisk {
  color: red;
}

.select-search__input {
  border: 1px solid #d3d3d4 !important;
}

/* typo */
.text-left {
  text-align: left;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* dropdown */
.dropdown .dropdown-item:not(.disabled):active {
  background-color: transparent;
  color: #212529;
}

/* modal */
.modal-backdrop.show {
  background-color: rgba(35, 31, 32, 1);
  opacity: 0.3;
  z-index: 2010;
}

.modal.show {
  z-index: 2015;
}

.modal-fullscreen,
.modal-fullscreen-sm-down,
.modal-fullscreen-md-down,
.modal-fullscreen-lg-down,
.modal-fullscreen-xxl-down {
  padding: 1rem !important;
}

/* .text-nowrap {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
} */

/* custom scroll */
.custom-scroll {
  scrollbar-width: thin;
}

.custom-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #ff8ab7;
  border-radius: 10px;
  border: 2px solid #ff8ab7
}

.custom-scroll::-webkit-scrollbar-track {
  background: #ff8ab7, 
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #ff8ab7
}

.MuiDivider-fullWidth {
  border-color: rgb(94, 94, 94) !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
}

/* TOQASTIFY */
.Toastify__toast-container {
  z-index: 9999;
}

.Toastify__toast-container.Toastify__toast-container--bottom-right {
  width: auto;
  z-index: 9999;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  width: auto;
  z-index: 9999;
}

/* radio */
.custom-radio > span:first-of-type {
  position: relative;
}

.custom-radio > span:first-of-type svg {
  display: none;
}

.custom-radio > span:first-of-type:before,
.custom-radio > span:first-of-type:after {
  content: "";
  display: block;
  border-radius: 100px;
}

.custom-radio > span:first-of-type:before {
  width: 24px;
  height: 24px;
  background-color: white;
  border: 1px solid #e5e5e5;
}

.custom-radio > span:first-of-type:after {
  width: 15px;
  height: 15px;
  background-color: #0051ba;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease;
}

.custom-radio.Mui-checked > span:first-of-type:after {
  transform: translate(-50%, -50%) scale(1);
}

.ant-picker-dropdown {
  z-index: 2020;
}

.dropdown-item:hover {
  background-color: rgba(28, 185, 154, 0.06);
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
}

/* Chrome, Safari, Edge, Opera */
.no-up-down input::-webkit-outer-spin-button,
.no-up-down input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type="number"].no-up-down {
  -moz-appearance: textfield;
} */
/* ทุกเบราว์เซอร์ */
input[type="number"].no-up-down {
  appearance: textfield; /* property มาตรฐาน */
  -moz-appearance: textfield; /* สำหรับ Firefox */
  -webkit-appearance: textfield; /* สำหรับ Chrome, Safari */
}

.btn-table-action:hover,
.btn-table-action:active,
.btn-table-action:focus {
  box-shadow: none !important;
}

.dropdown-table-action {
  border-color: #e5e5e5;
  border-radius: 3px;
}

/* Tooltip */
.MuiTooltip-popper {
  z-index: 9999999 !important;
}

/* Loading */
.loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.6);
  border-top-color: rgba(56, 80, 128, 0.8);
  border-radius: 50%;
  width: 3em;
  height: 3em;
  animation: spinner 1s linear infinite;
}

.loading-spinner-small {
  border-width: 2px;
  width: 1.5em;
  height: 1.5em;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.otp-link {
  color: black; /* สีปกติของลิงก์ */
  text-decoration: underline; /* เอาเส้นใต้ของลิงก์ออก */
}

.otp-link:hover {
  color: rgb(5, 92, 243); /* สีที่ต้องการเมื่อ hover */
  cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็นรูปมือเมื่อ hover */
}

.otp-link-enabled {
  color: rgb(175, 175, 175); /* สีปกติของลิงก์ */
  text-decoration: none; /* เอาเส้นใต้ของลิงก์ออก */
}

.request-otp {
  margin-top: 2px;
  text-align: right;
}

.error-otp {
  margin-top: 20px;
  text-align: center;
}

.logo-upload-slip {
  width: 50px;
  height: 50px;
}

.custom-dots li button::before {
  color: #EDEAFF; /* Default dot color */
  opacity: 1;
}

.custom-dots li.slick-active button::before {
  color: #B2A5F2; /* Active dot color */
  opacity: 1;
}

.input-field-container {
  transition: all 0.3s ease;
}

.input-field-container.sticky {
  position: fixed;
  top: 0;  /* Stick to the top */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  background-color: white; /* Set background color */
  z-index: 1000; /* Make sure it is above other content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  padding-inline: 15px;
  padding-bottom: 5px;
}

.pay-history-card {
  background-color: #FFF5F9;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 10px;
}